<template>
  <div class="div-1">
    <div class="div-2">
      <div class="div-3"></div>
      <div class="telechargez-lapplication-my-tt">
        Téléchargez l'application MyTT pour rejoigndre la file d'attente en
        ligne la prochaine fois
      </div>
      <div class="div-4">
        <picture>
          <source
            srcSet="https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F5676765390504ede8af174731ff58c8c?format=webp&width=100 100w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F5676765390504ede8af174731ff58c8c?format=webp&width=200 200w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F5676765390504ede8af174731ff58c8c?format=webp&width=400 400w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F5676765390504ede8af174731ff58c8c?format=webp&width=800 800w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F5676765390504ede8af174731ff58c8c?format=webp&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F5676765390504ede8af174731ff58c8c?format=webp&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F5676765390504ede8af174731ff58c8c?format=webp&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F5676765390504ede8af174731ff58c8c"
            type="image/webp"
          />
          <img
            src="https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F5676765390504ede8af174731ff58c8c"
            srcSet="https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F5676765390504ede8af174731ff58c8c?width=100 100w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F5676765390504ede8af174731ff58c8c?width=200 200w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F5676765390504ede8af174731ff58c8c?width=400 400w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F5676765390504ede8af174731ff58c8c?width=800 800w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F5676765390504ede8af174731ff58c8c?width=1200 1200w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F5676765390504ede8af174731ff58c8c?width=1600 1600w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F5676765390504ede8af174731ff58c8c?width=2000 2000w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F5676765390504ede8af174731ff58c8c"
            class="image"
          />
        </picture>
        <div class="builder-image-sizer image-sizer"></div>
      </div>
    </div>
    <div class="div-5">
      <picture>
        <source
          srcSet="https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Ffcdffdd01b7c4b9b886e08c926fdb6a3?format=webp&width=100 100w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Ffcdffdd01b7c4b9b886e08c926fdb6a3?format=webp&width=200 200w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Ffcdffdd01b7c4b9b886e08c926fdb6a3?format=webp&width=400 400w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Ffcdffdd01b7c4b9b886e08c926fdb6a3?format=webp&width=800 800w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Ffcdffdd01b7c4b9b886e08c926fdb6a3?format=webp&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Ffcdffdd01b7c4b9b886e08c926fdb6a3?format=webp&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Ffcdffdd01b7c4b9b886e08c926fdb6a3?format=webp&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Ffcdffdd01b7c4b9b886e08c926fdb6a3"
          type="image/webp"
        />
        <img
          src="https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Ffcdffdd01b7c4b9b886e08c926fdb6a3"
          srcSet="https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Ffcdffdd01b7c4b9b886e08c926fdb6a3?width=100 100w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Ffcdffdd01b7c4b9b886e08c926fdb6a3?width=200 200w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Ffcdffdd01b7c4b9b886e08c926fdb6a3?width=400 400w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Ffcdffdd01b7c4b9b886e08c926fdb6a3?width=800 800w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Ffcdffdd01b7c4b9b886e08c926fdb6a3?width=1200 1200w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Ffcdffdd01b7c4b9b886e08c926fdb6a3?width=1600 1600w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Ffcdffdd01b7c4b9b886e08c926fdb6a3?width=2000 2000w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2Ffcdffdd01b7c4b9b886e08c926fdb6a3"
          class="image"
        />
      </picture>
      <div class="builder-image-sizer image-sizer-2"></div>
    </div>
    <div class="felicitations-vous-avez-rejoi">
      Félicitations! Vous avez rejoint la file d'attente
    </div>
    <div class="div-6">
      <picture>
        <source
          srcSet="https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F564290cb6c6a41e488a4fc5f11aefbd9?format=webp&width=100 100w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F564290cb6c6a41e488a4fc5f11aefbd9?format=webp&width=200 200w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F564290cb6c6a41e488a4fc5f11aefbd9?format=webp&width=400 400w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F564290cb6c6a41e488a4fc5f11aefbd9?format=webp&width=800 800w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F564290cb6c6a41e488a4fc5f11aefbd9?format=webp&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F564290cb6c6a41e488a4fc5f11aefbd9?format=webp&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F564290cb6c6a41e488a4fc5f11aefbd9?format=webp&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F564290cb6c6a41e488a4fc5f11aefbd9"
          type="image/webp"
        />
        <img
          src="https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F564290cb6c6a41e488a4fc5f11aefbd9"
          srcSet="https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F564290cb6c6a41e488a4fc5f11aefbd9?width=100 100w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F564290cb6c6a41e488a4fc5f11aefbd9?width=200 200w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F564290cb6c6a41e488a4fc5f11aefbd9?width=400 400w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F564290cb6c6a41e488a4fc5f11aefbd9?width=800 800w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F564290cb6c6a41e488a4fc5f11aefbd9?width=1200 1200w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F564290cb6c6a41e488a4fc5f11aefbd9?width=1600 1600w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F564290cb6c6a41e488a4fc5f11aefbd9?width=2000 2000w, https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F564290cb6c6a41e488a4fc5f11aefbd9"
          class="image"
        />
      </picture>
      <div class="builder-image-sizer image-sizer-3"></div>
    </div>
    <div class="div-7"></div>
    <div class="div-8">Recommencer</div>
    <div class="cette-page-sera-fermee-dans-5">
      Cette page sera fermée dans 5 secondes
    </div>
  </div>
</template>


<script>
export default {
  name: 'Confirmation',
  data:()=>({
    msg:"Confirmation",
    key:'',
    secret:'',
    requestFailed: false,
    errors:[],
  }),
  methods:{
    checkForm:function() {
     
    },
    /* eslint-disable */
    validEmail:function(email) {
     
    },
    /* eslint-disable */
    submit(){
      
    },
   
  },  
  computed: {
  
  },
  created () {

   
  },
  mounted(){
     
  },
  components:{
  }
}
</script>

